<template lang="pug">
div.pa-4
  div.w-100.d-flex.justify-end
    v-btn(@click="$router.push('/back-office/auto-signing-distribution/edit')") {{ $t('edit') }}
  TableV2(
    :headers="headers"
    :items="items"
    :querySearch="filter => getAutoSigningDistribution(filter)"
    :isLoading="isLoading"
    isHiddenTableSearch
    isHiddenTableCount
    isHiddenPageCount)
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      headers: [
        { value: 'signer', text: 'ID', sortable: false },
        { value: 'signer_full_name', text: this.$t('group-harbor_master'), sortable: false },
        { value: 'distribution', text: `${this.$t('distribution')} (%)`, sortable: false },
        { value: 'can_auto_sign', text: this.$t('givesConsent'), sortable: false },
        { value: 'user_is_active', text: this.$t('activeAccount'), sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.autoSigningDistribution,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getAutoSigningDistribution'])
  }
}
</script>
